import {withAuthenticationRequired} from '@auth0/auth0-react'
import {Box, Typography} from '@mui/material'
import {Form, Formik, type FormikHelpers} from 'formik'
import * as React from 'react'

import {DynamicForm} from '~/components/dynamic-form'
import {ProfileFormInputs} from '~/components/form-element'
import {Header} from '~/components/header'
import {Progress} from '~/components/progress'
import {MessageSection} from '~/components/sections/message-section'
import {ProfileFormSection} from '~/components/sections/profile-form-section'
import {defaultRequiredFields} from '~/constants/shared'
import {useProfileInfo, useUpdateProfileInfo} from '~/hook/use-profile'
import type {FormValues, ProductName, RequiredProfileFields} from '~/types'
import {isErrorWithFormValue, mapDetailedProfileInfo} from '~/utils/profile'

function ProfilePage() {
  const profileInfo = useProfileInfo()
  const updateProfileInfo = useUpdateProfileInfo()

  const {requiredFields, productName} = React.useMemo(() => {
    const requiredFields: RequiredProfileFields = defaultRequiredFields
    const productName: ProductName = 'Dash'

    return {requiredFields, productName}
  }, [])

  if (!profileInfo) return null

  const userName = `${profileInfo?.firstName} ${profileInfo?.lastName}`
  const initialValues = mapDetailedProfileInfo(profileInfo)

  const saveProfile = (
    formValues: FormValues,
    {resetForm, setErrors, setSubmitting}: FormikHelpers<FormValues>,
  ) => {
    updateProfileInfo.mutate(
      {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        phoneNumber: formValues.phoneNumber,
        locationId: formValues.locationId,
        jobTitleId: formValues.jobTitleId,
        jobTitleOther: formValues.jobTitleOther,
        jobTitleGroupOther: formValues.jobTitleGroupOther,
        companyName: formValues.companyName,
      },
      {
        onSuccess: () => {
          resetForm({values: formValues})
        },
        onError: ex => {
          if (isErrorWithFormValue(ex)) {
            setErrors(ex.errors)
          }
        },
        onSettled: () => {
          setSubmitting(false)
        },
      },
    )
  }

  return (
    <>
      <Header userName={userName} productName={productName} />
      <Box sx={{my: 2, mx: 'auto', p: 5, maxWidth: '480px'}}>
        <DynamicForm initialValues={initialValues} fields={requiredFields}>
          {({validationSchema, values}) => (
            <Formik
              initialValues={values}
              onSubmit={saveProfile}
              validationSchema={validationSchema}
            >
              {({isValid, dirty}) => {
                return (
                  <Form>
                    <ProfileFormSection
                      title="Edit profile"
                      description={
                        <Box>
                          {values.isSso ? (
                            <Typography component="span" variant="body2">
                              Your name and password are managed by your
                              organization
                            </Typography>
                          ) : (
                            <>
                              <Typography
                                component="span"
                                variant="body2"
                              >{`You're updating your profile `}</Typography>
                              <Typography
                                component="span"
                                variant="body2"
                                data-ansarada-ccd
                              >
                                {profileInfo.email}
                              </Typography>
                            </>
                          )}
                        </Box>
                      }
                      submitButtonText="Save"
                      isSubmitting={updateProfileInfo.isLoading}
                      isDisableSubmit={!dirty || !isValid}
                    >
                      <ProfileFormInputs
                        profileValues={values}
                        fieldsConfig={{
                          hideTermsAndConditions: true,
                        }}
                      />
                    </ProfileFormSection>
                  </Form>
                )
              }}
            </Formik>
          )}
        </DynamicForm>
      </Box>
    </>
  )
}

export default withAuthenticationRequired(ProfilePage, {
  onRedirecting: () => <MessageSection title={<Progress />} />,
})
